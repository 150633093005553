<template>
  <component
    :is="tag"
    :class="['focus-block', { 'is-section': tag === 'section' }]"
  >
    <div class="section-inner">
      <template v-if="blok.items?.length > 0">
        <div v-if="blok.items?.length < 2" class="cards">
          <StoryblokComponent
            v-for="item in blok.items"
            :key="item._uid"
            :blok="item"
            :level="3"
            title-size="size-l"
            class="card"
            :is-centered="false"
            :size-hint="40"
          />
        </div>
        <div
          v-if="blok.items?.length >= 2"
          class="carousel-container carousel-full-width"
        >
          <GenericCarousel
            :items="blok.items"
            :slides-per-view="'auto'"
            :space-between="isTabletPortrait ? 10 : 18"
            class="carousel-navigation-bottom"
            :child-level="3"
            child-title-size="size-l"
            child-classes="card"
            :child-centered="false"
            :size-hint="40"
          />
        </div>
      </template>
    </div>
  </component>
</template>

<script setup>
defineProps({
  blok: {
    type: Object,
    required: true,
  },
  tag: {
    type: String,
    required: false,
    default: "div",
  },
});
const isTabletPortrait = useCustomBreakpoints("for-tablet-portrait-up");
</script>

<style lang="scss" scoped>
.focus-block {
  &.is-section {
    --margin-col-span: 1;
  }

  @include for-desktop-up {
    &.is-section {
      --margin-col-span: 3;
    }

    .section-inner {
      grid-column: calc(var(--margin-col-span) + 1) /
        calc(-1 * var(--margin-col-span) - 1);
    }
  }

  .cards {
    display: flex;
    gap: 1.125rem;
    justify-content: center;

    @include for-tablet-portrait-up {
      gap: 0.625rem;
    }
  }

  :deep(.media-text-cta) {
    gap: 1.25rem;
    padding-bottom: 1.5rem;

    @include for-tablet-portrait-up {
      flex-direction: row-reverse;
      padding: 0.625rem;
      width: calc((1 - var(--margin-col-span) / 10) * min($max-width, 100vw));
      min-height: 23.9375rem;
      gap: 0;
    }
  }

  :deep(.media-text-cta .media),
  :deep(.media-text-cta .media-carousel),
  :deep(.media-text-cta .media-carousel .swiper),
  :deep(.media-text-cta .media-carousel .swiper-slide *) {
    @include for-tablet-portrait-up {
      height: 100%;
    }
  }
  :deep(.media-text-cta .text-cta-wrapper) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    @include for-tablet-portrait-up {
      height: 100%;
      padding: 1.875rem 3.875rem 1.875rem 3.875rem;
      justify-content: space-between;
    }
  }

  :deep(.media-text-cta .text-wrapper) {
    max-width: 20rem;
    margin: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  :deep(.media-text-cta .title) {
    @include for-tablet-portrait-up {
      margin-bottom: 0.75rem;
    }
  }

  :deep(.media-text-cta > *) {
    @include for-tablet-portrait-up {
      width: 50% !important;
    }
  }
}
</style>
